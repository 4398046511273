/// @ts-check
import { SqliteClient, SqliteClientTypeEnum } from "@magieno/sqlite-client";
import $ from "jquery";

/** @type {any} */
const ANY_NULL = null;

let initialized = localStorage.getItem('initialized') === 'true';
let useLocalDb = localStorage.getItem('useLocalDb') === 'true';

/**
 * @template T
 * Create a promise with resolve and reject functions
 * @returns {{ promise: Promise<T>, resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void }}
 */
function destructurePromise() {
    /** @type {(value: T | PromiseLike<T>) => void} */
    let resolve = ANY_NULL;
    /** @type {(reason?: any) => void} */
    let reject = ANY_NULL;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return { promise, resolve, reject };
}

class RemoteAdapter {
    // TODO
}

class LocalAdapter {
    /**
     * @param {SqliteClient} db
     */
    constructor(db) {
        this._db = db;
    }

    async list(path, from, to) {
        try {
            let dirId = 0;
            if (path !== '') {
                const pathParts = path.split('/');
                const dirName = pathParts.pop();
                const dirPath = pathParts.join('/');
                const dir = await this._db.executeSql(`SELECT id FROM dirs WHERE path = ? AND name = ? LIMIT 1;`, [dirPath, dirName]);
                if (dir.length === 0) {
                    return [];
                }
                dirId = dir[0][0];
            }
            const entries = await this._db.executeSql(`SELECT name, last_modified, size, 'inode/directory' AS type FROM dirs WHERE path = ? UNION ALL SELECT name, last_modified, size, type FROM files WHERE dir_id = ? LIMIT ?, ?;`, [path, dirId, from, to]);
            return entries.map(([name, lastModified, size, type]) => ({
                name,
                lastModified,
                size,
                type,
                isDir: type === 'inode/directory',
            }));
        } catch (e) {
            debugger;
            console.error(e);
            return [];
        }
    }
    // TODO
}

// TODO
let spinner = $('<div>Loading...</div>');

function showSpinner() {
    $('body').append(spinner);
}

function hideSpinner() {
    spinner.remove();
}

/**
 * @param {string} initialMessage
 * @returns {Promise<SqliteClient | 'remote'>}
 */
async function dbMissingOrCorruptedPrompt(initialMessage) {
    const { promise, resolve } = destructurePromise();
    const fileInput = $('<input type="file">');
    /** @type {any} */
    let pickedOption = 'download';
    const downloadSize = $('<span>(loading... MB)</span>');
    (async () => {
        try {
            const response = await fetch('/download/0', { method: 'HEAD' });
            const totalSize = parseInt(response.headers.get('X-Total-Size') || '0');
            const totalSizeStr = totalSize < 1024 ? `${totalSize} B` : totalSize < 1024 * 1024 ? `${(totalSize / 1024).toFixed(2)} KB` : `${(totalSize / 1024 / 1024).toFixed(2)} MB`;
            downloadSize.text(`(${totalSizeStr})`);
        } catch (e) {
            console.error(e);
        }
    })();
    const downloadOption = $('<div></div>').append($('<label><input type="radio" name="db-option" value="download" checked> Download database from server </label>').append(downloadSize));
    const fileOption = $('<div></div>').append($('<label><input type="radio" name="db-option" value="file"> Load databse from local file </label>').append(fileInput));
    const remoteOption = $('<div><label><input type="radio" name="db-option" value="remote"> Use remote database</label></div>');
    const okButton = $('<button>Ok</button>').on('click', () => {
        pickedOption = $('input[name="db-option"]:checked').val();
        if (pickedOption !== 'file') {
            return resolve(void 0);
        }
        let file = fileInput.prop('files')[0];
        if (!file || file.name.match(/\.sqlite3?$/) === null) {
            alert('Please select a valid SQLite database file');
            return;
        }
        resolve(void 0);
    });
    const message = $('<div></div>').append($('<div></div>').text(initialMessage), downloadOption, fileOption, remoteOption, okButton);
    hideSpinner();
    $('body').append(message);
    await promise;
    message.remove();
    showSpinner();
    if (pickedOption === 'file') {
        try {
            const dir = await navigator.storage.getDirectory();
            const chosenFile = fileInput.prop('files')[0];
            const newFile = await dir.getFileHandle('database.sqlite3', { create: true });
            const writable = await newFile.createWritable();
            await writable.write(chosenFile);
            await writable.close();
        } catch (e) {
            const { promise, resolve } = destructurePromise();
            const button = $('<button>Ok</button>').on('click', () => resolve(void 0));
            const message = $('<div></div>').append($('<div></div>').text(`Failed to save file: ${e.message}`), button);
            hideSpinner();
            $('body').append(message);
            await promise;
            message.remove();
            showSpinner();
        }
        return await checkFileUI();
    }
    else if (pickedOption === 'download') {
        /** @type {FileSystemWritableFileStream | null} */
        let writable = null;
        /** @type {FileSystemFileHandle | null} */
        let file = null;
        try {
            const dir = await navigator.storage.getDirectory();
            file = await dir.getFileHandle('database.sqlite3', { create: true });
            writable = await file.createWritable();
            localStorage.setItem('sqliteFileDownloading', 'true');
        } catch (e) {
            const { promise, resolve } = destructurePromise();
            const button = $('<button>Ok</button>').on('click', () => resolve(void 0));
            const message = $('<div></div>').append($('<div></div>').text(`Failed to save file: ${e.message}`), button);
            hideSpinner();
            $('body').append(message);
            await promise;
            message.remove();
            showSpinner();
            return await checkFileUI();
        }
        hideSpinner();
        const progress = $('<div></div>');
        $('body').append(progress);
        let currentChunk = 0;
        let totalChunks = 0;
        let totalSize = 0;
        let currentSize = 0;
        let lastUpdate = 0;
        let previousSize = 0;
        /** @param {boolean} [force] */
        const updateProgress = (force) => {
            const now = Date.now();
            if (force || lastUpdate + 500 < now) {
                const speed = (currentSize - previousSize) / ((now - lastUpdate) / 1000);
                const speedStr = speed < 1024 ? `${speed.toFixed(2)} B/s` : speed < 1024 * 1024 ? `${(speed / 1024).toFixed(2)} KB/s` : `${(speed / 1024 / 1024).toFixed(2)} MB/s`;
                const totalSizeStr = totalSize === 0 ? '?' : totalSize < 1024 ? `${totalSize} B` : totalSize < 1024 * 1024 ? `${(totalSize / 1024).toFixed(2)} KB` : `${(totalSize / 1024 / 1024).toFixed(2)} MB`;
                const currentSizeStr = currentSize < 1024 ? `${currentSize} B` : currentSize < 1024 * 1024 ? `${(currentSize / 1024).toFixed(2)} KB` : `${(currentSize / 1024 / 1024).toFixed(2)} MB`;
                progress.text(`Downloading chunk ${currentChunk + 1} of ${totalChunks === 0 ? '?' : totalChunks} (${currentSizeStr} of ${totalSizeStr} bytes, ${speedStr})`);
                lastUpdate = now;
                previousSize = currentSize;
            }
        }
        do {
            updateProgress();
            /** @type {Response | null} */
            let response = null;
            try {
                response = await fetch(`/download/${currentChunk}`);
            } catch (e) {
                console.error(e);
            }
            if (!response || !response.ok || response.headers.get('X-Chunks-Count') === null || response.headers.get('X-Total-Size') === null || response.body === null) {
                const { promise, resolve } = destructurePromise();
                const buttonRetry = $('<button>Retry</button>').on('click', () => resolve('retry'));
                const buttonCancel = $('<button>Cancel</button>').on('click', () => resolve('cancel'));
                const message = $('<div></div>').append($('<div></div>').text(`Failed to download chunk ${currentChunk + 1}`), buttonRetry, buttonCancel);
                progress.remove();
                $('body').append(message);
                const result = await promise;
                message.remove();
                const cancel = result === 'cancel';
                if (cancel) {
                    showSpinner();
                    return await checkFileUI();
                } else {
                    $('body').append(progress);
                    continue;
                }
            }
            if (currentChunk === 0) {
                totalChunks = parseInt(response.headers.get('X-Chunks-Count') || '0');
                totalSize = parseInt(response.headers.get('X-Total-Size') || '0');
                updateProgress(true);
            }

            try {
                const reader = response.body.getReader();
                let done = false;
                let value = null;
                while (!done) {
                    ({ done, value } = await reader.read());
                    if (value == null || done) {
                        break;
                    }
                    currentSize += value.byteLength;
                    updateProgress();
                    await writable.write(value);
                }
            } catch (e) {
                const { promise, resolve } = destructurePromise();
                const button = $('<button>Ok</button>').on('click', () => resolve(void 0));
                const message = $('<div></div>').append($('<div></div>').text(`Failed to write file: ${e.message}`), button);
                progress.remove();
                $('body').append(message);
                await promise;
                message.remove();
                showSpinner();
                return await checkFileUI();
            }
            currentChunk++;
        } while (currentChunk < totalChunks);
        progress.remove();
        showSpinner();
        try {
            await writable.close();
            localStorage.removeItem('sqliteFileDownloading');
        }
        catch (e) {
            const { promise, resolve } = destructurePromise();
            const button = $('<button>Ok</button>').on('click', () => resolve(void 0));
            const message = $('<div></div>').append($('<div></div>').text(`Failed to close file: ${e.message}`), button);
            hideSpinner();
            $('body').append(message);
            await promise;
            message.remove();
            showSpinner();
        }
        return await checkFileUI();
    } else {
        return 'remote';
    }
}

/**
 * @param {boolean} firstRun
 * @returns {Promise<SqliteClient | 'remote'>}
 */
async function checkFileUI(firstRun = false) {
    let fileExists = false;
    try {
        if (!navigator || !navigator.storage || typeof navigator.storage.getDirectory !== 'function') {
            throw new Error('OPFS is not supported in this browser');
        }
        const dir = await navigator.storage.getDirectory();
        const files = dir.keys();
        for await (const file of files) {
            if (file === 'database.sqlite3') {
                fileExists = true;
                break;
            }
        }
        if (fileExists && localStorage.getItem('sqliteFileDownloading') === 'true') {
            // Remove partially downloaded file
            /** @type {any} */
            const file = await dir.getFileHandle('database.sqlite3');
            await file.remove();
            fileExists = false;
        }
    } catch (e) {
        const { promise, resolve } = destructurePromise();
        const button = $('<button>Ok</button>').on('click', () => resolve(void 0));
        let messageStr = `${(e || {}).message || e}`;
        const message = $('<div></div>').append($('<div></div>').text(`${messageStr}\nUsing remote database instead`), button);
        hideSpinner();
        $('body').append(message);
        await promise;
        message.remove();
        showSpinner();
        return 'remote';
    }
    if (!fileExists) {
        if (firstRun) {
            return dbMissingOrCorruptedPrompt('To use this service, you need to download the database file. Please select what to do:');
        }
        return dbMissingOrCorruptedPrompt('Database file not found. Please select what to do:');
    }
    return checkDbUI();
}

/**
 * @returns {Promise<SqliteClient | 'remote'>}
 */
async function checkDbUI() {
    /** @type {SqliteClient} */
    let db = ANY_NULL;
    try {
        try {
            db = new SqliteClient({
                type: SqliteClientTypeEnum.OpfsWorker,
                filename: '/database.sqlite3',
                sqliteWorkerPath: "/js/sqlite3-client-worker.js",
                flags: "c",
            });
            await db.init();
        } catch (e) {
            let message = (e || {}).message || e;
            throw new Error(`Failed to open local database: ${message}`);
        }
        /** @type {Array<[string, string]>} */
        let tables = [];
        try {
            tables = await db.executeSql(`SELECT name, sql FROM sqlite_master WHERE type='table' AND name IN ('files', 'dirs', 'last_modified');`);
        } catch (e) {
            let message = `${(e || {}).message || e}`;
            if (message.startsWith('SQLITE_NOTADB') || message.startsWith('SQLITE_CORRUPT')) {
                return dbMissingOrCorruptedPrompt('Database file is corrupted. Please select what to do:');
            }
            throw new Error(`Failed to query local database: ${message}`);
        }
        if (tables.length < 3) {
            return dbMissingOrCorruptedPrompt(`Database file is missing ${tables.length ? 'some' : 'all'} tables. Please select what to do:`);
        }
        /** @type {Record<string, RegExp>} */
        const expectedSchema = {
            files: /^CREATE\s+TABLE\s+files\s*\(\s*id\s+INTEGER\s+PRIMARY\s+KEY\s+AUTOINCREMENT\s*,\s*dir_id\s+INTEGER\s+NOT\s+NULL\s*,\s*name\s+TEXT\s+NOT\s+NULL\s*,\s*encoded\s+TEXT\s+NOT\s+NULL\s*,\s*last_verified\s+INTEGER\s+NOT\s+NULL\s*,\s*last_modified\s+INTEGER\s+NOT\s+NULL\s*,\s*type\s+TEXT\s+NOT\s+NULL\s*,\s*size\s+INTEGER\s+NOT\s+NULL\s*,\s*size_p\s+INTEGER\s+NOT\s+NULL\s*,\s*status\s+INTEGER\s+NOT\s+NULL\s*\)$/,
            dirs: /^CREATE\s+TABLE\s+dirs\s*\(\s*id\s+INTEGER\s+PRIMARY\s+KEY\s+AUTOINCREMENT\s*,\s*path\s+TEXT\s+NOT\s+NULL\s*,\s*name\s+TEXT\s+NOT\s+NULL\s*,\s*encoded\s+TEXT\s+NOT\s+NULL\s*,\s*last_verified\s+INTEGER\s+NOT\s+NULL\s*,\s*last_modified\s+INTEGER\s+NOT\s+NULL\s*,\s*size\s+INTEGER\s+NOT\s+NULL\s*,\s*size_p\s+INTEGER\s+NOT\s+NULL\s*,\s*status\s+INTEGER\s+NOT\s+NULL\s*\)$/,
            last_modified: /^CREATE\s+TABLE\s+last_modified\s*\(\s*id\s+INTEGER\s+PRIMARY\s+KEY\s+AUTOINCREMENT\s*,\s*last_modified\s+INTEGER\s+NOT\s+NULL\s*\)$/
        };
        for (const [name, sql] of tables) {
            if (!expectedSchema[name].test(sql)) {
                return dbMissingOrCorruptedPrompt('Database file has incorrect schema. Please select what to do:');
            }
        }
        for (const [name] of tables) {
            /** @type {Array<[number]>} */
            let count = [[0]];
            try {
                count = await db.executeSql(`SELECT COUNT(*) FROM ${name};`);
            } catch (e) {
                let message = `${(e || {}).message || e}`;
                if (message.startsWith('SQLITE_NOTADB') || message.startsWith('SQLITE_CORRUPT')) {
                    return dbMissingOrCorruptedPrompt('Database file is corrupted. Please select what to do:');
                }
                throw new Error(`Failed to query local database: ${message}`);
            }
            if (count[0][0] <= 0) {
                return dbMissingOrCorruptedPrompt('Database file has empty tables. Please select what to do:');
            }
        }
    } catch (e) {
        const { promise, resolve } = destructurePromise();
        const button = $('<button>Ok</button>').on('click', () => resolve(void 0));
        const message = $('<div></div>').append($('<div></div>').text(`${e.message}\nUsing remote database instead`), button);
        hideSpinner();
        $('body').append(message);
        await promise;
        message.remove();
        showSpinner();
        return 'remote';
    }
    return db;
}

async function main() {
    let adapter = null;
    if (!initialized) {
        showSpinner();
        const result = await checkFileUI(true);
        if (result === 'remote') {
            useLocalDb = false;
            localStorage.setItem('useLocalDb', 'false');
            adapter = new RemoteAdapter();
        } else {
            useLocalDb = true;
            localStorage.setItem('useLocalDb', 'true');
            adapter = new LocalAdapter(result);
        }
        initialized = true;
        localStorage.setItem('initialized', 'true');
        hideSpinner();
    } else if (useLocalDb) {
        showSpinner();
        const result = await checkFileUI();
        if (result === 'remote') {
            useLocalDb = false;
            localStorage.setItem('useLocalDb', 'false');
            adapter = new RemoteAdapter();
        } else {
            adapter = new LocalAdapter(result);
        }
        hideSpinner();
    } else {
        adapter = new RemoteAdapter();
    }
    globalThis.adapter = adapter;

    let currentMode = 'list'; // list, search
    let currentPath = '';
    let currentFrom = 0;
    let currentTo = 20000;

    const table = $('<table></table>');
    $('body').append(table);

    const nameHeader = $('<th>Name</th>');
    const lastModifiedHeader = $('<th>Last Modified</th>');
    const sizeHeader = $('<th>Size</th>');
    const typeHeader = $('<th>Type</th>');
    const entriesHeader = $('<thead></thead>').append($('<tr></tr>').append(nameHeader, lastModifiedHeader, sizeHeader, typeHeader));

    function createEntriesParentDir(path) {
        if (path === '') {
            return $();
        }
        const pathUp = path.split('/').slice(0, -1).join('/');
        const a = $('<a></a>').text('..').attr('href', `/list${pathUp === '' ? '' : '/'}${pathUp}`).on('click', async (e) => {
            e.preventDefault();
            currentPath = pathUp;
            await loadNewList(0);
        });
        return $('<tr></tr>').append($('<td colspan="4"></td>').append(a));
    }

    const loadPreviousOrMoreRow = $('<tr></tr>').append($('<td>Load previous page...</td>').on('click', async () => {
        await loadNewList(currentFrom >= 20000 ? currentFrom - 20000 : 0);
    }), $('<td colspan="3">Load more...</td>').on('click', async () => {
        await loadListPrepend(20000);
    }));

    const loadNextOrMoreRow = $('<tr></tr>').append($('<td>Load next page...</td>').on('click', async () => {
        await loadNewList(currentFrom + 20000);
    }), $('<td colspan="3">Load more...</td>').on('click', async () => {
        await loadListAppend(20000);
    }));

    const loadingUpRow = $('<tr><td>Loading...</td></tr>');
    const loadingDownRow = $('<tr><td>Loading...</td></tr>');
    const loadingRow = $('<tr><td>Loading...</td></tr>');

    const entriesList = $('<tbody></tbody>').append(loadingRow);
    table.append(entriesHeader, entriesList);

    /** @type {{ loading: boolean, type: string, cancels: Array<()=>void> }} */
    let loadingState = {
        loading: false,
        type: 'replace',
        cancels: [],
    };

    loadNewList(0);

    function createRow(entry, path) {
        const row = $('<tr></tr>');
        const nameLink = $('<a></a>').text(entry.name);
        let href = `/list${path === '' ? '' : '/'}${path.split('/').map(encodeURIComponent).join('/')}/${encodeURIComponent(entry.name)}`;
        if (entry.isDir) {
            nameLink.on('click', async (e) => {
                e.preventDefault();
                currentPath += `${currentPath === '' ? '' : '/'}${entry.name}`;
                await loadNewList(0);
            });
        } else {
            href = 'https://theponyarchive.com/archive' + href;
        }
        nameLink.attr('href', href);
        row.append($('<td></td>').append(nameLink));
        row.append($('<td></td>').text(new Date(entry.lastModified).toLocaleString()));
        row.append($('<td></td>').text(entry.size));
        row.append($('<td></td>').text(entry.type));
        return row;
    }

    async function loadNewList(from) {
        if (loadingState.loading) {
            if (loadingState.type === 'replace') {
                return;
            }
        }
        loadingState.loading = true;
        loadingState.type = 'replace';
        loadingState.cancels.forEach(cancel => cancel());
        loadingState.cancels.length = 0;
        entriesList.empty().append(loadingRow);
        currentFrom = from;
        currentTo = from + 20000;
        const entries = await adapter.list(currentPath, currentFrom, currentTo);
        loadingState.loading = false;
        loadingRow.remove();
        entriesList.append(createEntriesParentDir(currentPath));
        for (const entry of entries) {
            const row = createRow(entry, currentPath);
            entriesList.append(row);
        }
        if (currentFrom > 0) {
            entriesList.prepend(loadPreviousOrMoreRow);
        }
        if (entries.length === 20000) {
            entriesList.append(loadNextOrMoreRow);
        }
    }

    function loadListPrepend(count) {
        // TODO
    }

    function loadListAppend(count) {
        // TODO
    }

    // const searchAsYouType = $('<input type="checkbox" checked>').on('change', () => {
    //     // TODO
    // });
    // const searchInput = $('<input type="text" placeholder="Search...">').on('input', async () => {
    //     // TODO
    // });
}

main();

/** @type {any} */
const w = globalThis;
w.$ = $;
w.SqliteClient = SqliteClient;
w.SqliteClientTypeEnum = SqliteClientTypeEnum;